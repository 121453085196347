package com.damda.webapp.views.core

import kotlinx.browser.window
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.px

data class JSViewPadding(
    val left: CSSSizeValue<CSSUnit.px>,
    val top: CSSSizeValue<CSSUnit.px>,
    val right: CSSSizeValue<CSSUnit.px>,
    val bottom: CSSSizeValue<CSSUnit.px>
)

fun CSSSizeValue<CSSUnit.px>.dynamicSize(): CSSSizeValue<CSSUnit.px> {
    val baseSize = this.value
    val minSize = baseSize - baseSize * 0.1
    val maxSize = baseSize + baseSize * 0.1

    // 화면 크기를 가져와서 비례적으로 계산
    val viewportWidth = window.innerWidth
    val dynamicSize = minSize + (maxSize - minSize) * (viewportWidth / 1920.0)

    return dynamicSize.px
}