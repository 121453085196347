package com.damda.webapp.views.core

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlin.random.Random

object FirebaseAnalytics {
    private val eventQueue: MutableList<Pair<String, dynamic>> = mutableListOf()
    private val coroutineScope = MainScope()

    fun logEvent(eventName: String, params: dynamic = js("{}")) {
        eventQueue.add(Pair(eventName, params))

        coroutineScope.launch {
            while (true) {
                delay(1000) // 1초 지연

                val analyticsCheck = js("window.analytics")
                if (analyticsCheck != null) {
                    for ((eventName, params) in eventQueue) {
                        try {
                            js("window").logEvent(eventName, params)
                        } catch (e: dynamic) {
                            console.error("Error logging event: $eventName", e)
                        }
                    }
                    eventQueue.clear()
                    break
                }
            }
        }
    }

    @Composable
    fun logPageEntryInComposable(page: String, eventName: String = "screen_view") {
        DisposableEffect(Unit) {
            val params = js("{}")
            params.uuid = generateUUID()
            params.firebase_screen = page
            params.firebase_screen_class = "${page}_class"

            logEvent(eventName, params)

            onDispose {

            }
        }
    }

    fun clickLogEvent(
        page: String,
        clickEvent: String,
        eventName: String = "click_item"
    ) {
        val params = js("{}")
        params.uuid = generateUUID()
        params.firebase_screen = page
        params.firebase_screen_class = "${page}_class"
        params.clicked_item = clickEvent

        logEvent(eventName, params)
    }
}

fun generateUUID(): String {
    val chars = "0123456789abcdef"
    fun randomChar() = chars[Random.nextInt(chars.length)]

    return "${(1..8).map { randomChar() }.joinToString("")}-${(1..4).map { randomChar() }.joinToString("")}-${(1..4).map { randomChar() }.joinToString("")}-${(1..4).map { randomChar() }.joinToString("")}-${(1..12).map { randomChar() }.joinToString("")}"
}