package com.damda.webapp.views.datas

data class TableHeader(
    val columnNumber: Int,
    val title: String
)

val tableHeaders = listOf(
    TableHeader(0, title = "보장내용"),
    TableHeader(1, title = "30세"),
    TableHeader(2, title = "100세")
)