package com.damda.webapp.views.dialogs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.insurance.themes.Colors
import com.damda.webapp.views.core.dynamicSize
import com.damda.webapp.views.layouts.ActionButton
import com.damda.webapp.views.layouts.FormContainer
import com.damda.webapp.views.layouts.RadioButtonSection
import com.damda.webapp.views.themes.toCssColor
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.maxHeight
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.overflowY
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Li
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Ul

@Composable
fun PrivacyDialog(
    onChangeConsentGiven: (Boolean) -> Unit
) {
    val confirmPrivacyText = "동의"
    var agreeText by remember { mutableStateOf("거부") }

    Div({
        style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            alignItems(AlignItems.Center)
            justifyContent(JustifyContent.Center)
            position(Position.Fixed)
            top(0.px)
            left(0.px)
            width(100.percent)
            height(100.percent)
            backgroundColor(rgba(0, 0, 0, 0.5))
        }
    }) {
        Div({
            style {
                backgroundColor(Color.white)
                padding(16.px)
                borderRadius(8.px)
                maxWidth(400.px)
                width(100.percent)
                property("text-align", "left")
            }
        }) {
            H2 { Text("개인정보 수집 및 이용 동의서") }

            Div({
                style {
                    border {
                        style = LineStyle.Solid
                        color = Colors.secondaryGreyColor.toCssColor()
                        width = 1.px
                    }
                    borderRadius(8.px)
                    padding(16.px)
                    maxHeight(50.vh) // 고정된 높이 설정
                    overflowY("auto") // 세로 스크롤 가능하게 설정
                }
            }) {
                H4 { Text("1. 개인정보의 수집 및 이용 목적") }
                P({
                    style {
                        fontSize(12.px.dynamicSize())
                    }
                }) {
                    Text("저희는 귀하의 개인정보를 보험 상담을 위해 다음과 같이 수집하고자 합니다. 수집된 개인정보는 보험 상담 외의 다른 목적으로 사용되지 않습니다.")
                }

                H4 { Text("2. 수집하는 개인정보의 항목") }
                Ul({
                    style {
                        fontSize(12.px.dynamicSize())
                    }
                }) {
                    Li { Text("이름") }
                    Li { Text("핸드폰 번호") }
                    Li { Text("주소") }
                }

                H4 { Text("3. 개인정보의 보유 및 이용 기간") }
                P({
                    style {
                        fontSize(12.px.dynamicSize())
                    }
                }) {
                    Text("수집된 개인정보는 보험 상담이 완료된 후 즉시 폐기됩니다. 귀하의 개인정보는 상담 목적이 달성된 이후에는 어떠한 방식으로도 보관되지 않습니다.")
                }

                H4 { Text("4. 개인정보의 폐기 절차 및 방법") }
                Ul({
                    style {
                        fontSize(12.px.dynamicSize())
                    }
                }) {
                    Li { Text("폐기 절차: 상담 목적이 달성된 후, 수집된 개인정보는 즉시 폐기 절차를 거칩니다.") }
                    Li { Text("폐기 방법: 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이 문서 형태의 정보는 분쇄기로 파기합니다.") }
                }

                H4 { Text("5. 동의를 거부할 권리 및 동의 거부에 따른 불이익") }
                P({
                    style {
                        fontSize(12.px.dynamicSize())
                    }
                }) {
                    Text("귀하는 개인정보 제공에 동의하지 않을 권리가 있으며, 동의를 거부하실 경우 보험 상담 서비스 제공이 제한될 수 있습니다.")
                }
            }

            P {
                Text("위와 같이 개인정보를 수집 및 이용하는 것에 동의하십니까?")
            }
            RadioButtonSection(
                firstItemTitle = "동의",
                secondItemTitle = "거부",
                clickedItem = agreeText,
                onClick = { agreeText = it }
            )
            ActionButton("확인", modifier = {
                width(100.percent)
                border(width = 0.px, style = LineStyle.None) // 버튼의 테두리를 없앰
                backgroundColor(Colors.secondaryColor.toCssColor())
                // text css
                color(Color.white)
                fontSize(16.px.dynamicSize())
                fontWeight(700)
                marginTop(32.px)
                marginBottom(16.px)
            }) {
                // 버튼 클릭 시 실행할 동작
                onChangeConsentGiven(agreeText == confirmPrivacyText)
            }
        }
    }
}

@Composable
fun PrivacyFullScreenDialog(
    onChangeConsentGiven: (Boolean) -> Unit
) {
    val confirmPrivacyText = "동의"
    var agreeText by remember { mutableStateOf("거부") }

    FormContainer {
        Div({
            style {
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Column)
                alignItems(AlignItems.Center)
                justifyContent(JustifyContent.Center)
                position(Position.Fixed)
                top(0.px)
                left(0.px)
                width(100.percent)
                height(100.percent)
                backgroundColor(rgba(0, 0, 0, 0.5))
            }
        }) {
            Div({
                style {
                    backgroundColor(Color.white)
                    padding(16.px)
                    borderRadius(8.px)
                    maxWidth(400.px)
                    width(100.percent)
                    property("text-align", "left")
                }
            }) {
                Div(
                    {
                        style {
                            paddingTop(24.px)
                            paddingBottom(24.px)
                            paddingLeft(16.px)
                            paddingRight(16.px)
                        }
                    }
                ) {
                    H2 { Text("개인정보 수집 및 이용 동의서") }

                    Div({
                        style {
                            border {
                                style = LineStyle.Solid
                                color = Colors.secondaryGreyColor.toCssColor()
                                width = 1.px
                            }
                            marginTop(24.px)
                            marginBottom(24.px)
                            marginLeft(16.px)
                            marginRight(16.px)
                            borderRadius(8.px)
                            padding(16.px)
                            maxHeight(50.vh) // 고정된 높이 설정
                            overflowY("auto") // 세로 스크롤 가능하게 설정
                        }
                    }) {
                        H4 { Text("1. 개인정보의 수집 및 이용 목적") }
                        P({
                            style {
                                fontSize(12.px.dynamicSize())
                            }
                        }) {
                            Text("저희는 귀하의 개인정보를 보험 상담을 위해 다음과 같이 수집하고자 합니다. 수집된 개인정보는 보험 상담 외의 다른 목적으로 사용되지 않습니다.")
                        }

                        H4 { Text("2. 수집하는 개인정보의 항목") }
                        Ul({
                            style {
                                fontSize(12.px.dynamicSize())
                            }
                        }) {
                            Li { Text("이름") }
                            Li { Text("핸드폰 번호") }
                            Li { Text("주소") }
                        }

                        H4 { Text("3. 개인정보의 보유 및 이용 기간") }
                        P({
                            style {
                                fontSize(12.px.dynamicSize())
                            }
                        }) {
                            Text("수집된 개인정보는 보험 상담이 완료된 후 즉시 폐기됩니다. 귀하의 개인정보는 상담 목적이 달성된 이후에는 어떠한 방식으로도 보관되지 않습니다.")
                        }

                        H4 { Text("4. 개인정보의 폐기 절차 및 방법") }
                        Ul({
                            style {
                                fontSize(12.px.dynamicSize())
                            }
                        }) {
                            Li { Text("폐기 절차: 상담 목적이 달성된 후, 수집된 개인정보는 즉시 폐기 절차를 거칩니다.") }
                            Li { Text("폐기 방법: 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이 문서 형태의 정보는 분쇄기로 파기합니다.") }
                        }

                        H4 { Text("5. 동의를 거부할 권리 및 동의 거부에 따른 불이익") }
                        P({
                            style {
                                fontSize(12.px.dynamicSize())
                            }
                        }) {
                            Text("귀하는 개인정보 제공에 동의하지 않을 권리가 있으며, 동의를 거부하실 경우 보험 상담 서비스 제공이 제한될 수 있습니다.")
                        }
                    }

                    P {
                        Text("위와 같이 개인정보를 수집 및 이용하는 것에 동의하십니까?")
                    }
                    RadioButtonSection(
                        firstItemTitle = "동의",
                        secondItemTitle = "거부",
                        clickedItem = agreeText,
                        onClick = { agreeText = it }
                    )
                    ActionButton("확인", modifier = {
                        width(100.percent)
                        border(width = 0.px, style = LineStyle.None) // 버튼의 테두리를 없앰
                        backgroundColor(Colors.secondaryColor.toCssColor())
                        // text css
                        color(Color.white)
                        fontSize(16.px.dynamicSize())
                        fontWeight(700)
                        marginTop(32.px)
                        marginBottom(16.px)
                    }) {
                        // 버튼 클릭 시 실행할 동작
                        onChangeConsentGiven(agreeText == confirmPrivacyText)
                    }
                }

            }
        }
    }
}