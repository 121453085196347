package com.damda.webapp.views.screens

import androidx.compose.runtime.Composable
import com.damda.insurance.networks.ApiClient
import com.damda.webapp.views.core.FirebaseAnalytics
import com.damda.webapp.views.layouts.FormContainer
import com.damda.webapp.views.texts.FormSubTitle
import com.damda.webapp.views.texts.FormTitle
import com.damda.webapp.views.texts.LabelTitle
import com.damda.webapp.views.viewmodels.MainViewModel
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img

@Composable
fun StepCompleteScreen(windowSize: ComposeWindowWidthSizeClass, mainViewModel: MainViewModel) {
    FirebaseAnalytics.logPageEntryInComposable("StepComplete")
    MainScope().launch {
        console.log(mainViewModel.getAllResult())
        ApiClient.postData(ApiClient.EXCEL_URL, mainViewModel.getAllResult()) {}
    }
    FormContainer {
        Div {
            FormTitle("보험 상담", "신청을 완료했습니다.")
            FormSubTitle("빠른 시일 내에", "고객님에게 좋은 소식 전달드리겠습니다.")

            LabelTitle(title = "더 많은 정보 알아보기") {
                marginTop(60.px)
            }
            if (windowSize == ComposeWindowWidthSizeClass.Small) {
                BannerList()
            } else {
                Div(attrs = {
                    onClick {
                        window.open("https://cafe.naver.com/yesiambaby/2")
                    }
                }) {
                    Img("event_banner.png", attrs = {
                        style { width(100.percent); height(auto) }
                    })
                }
                Div(attrs = {
                    onClick { window.open("https://cafe.naver.com/yesiambaby?iframe_url=/ArticleList.nhn%3Fsearch.clubid=31192653%26search.menuid=36%26search.boardtype=L") }
                }) {
                    Img("event_banner2.png", attrs = {
                        style { width(100.percent); height(auto) }
                    })
                }
            }

        }
    }
}