package com.damda.webapp.views.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.webapp.views.core.FirebaseAnalytics
import com.damda.webapp.views.layouts.DropDown
import com.damda.webapp.views.layouts.FormContainer
import com.damda.webapp.views.layouts.FormNextButton
import com.damda.webapp.views.layouts.RadioButtonSection
import com.damda.webapp.views.texts.FormSubTitle
import com.damda.webapp.views.texts.FormTitle
import com.damda.webapp.views.texts.LabelTitle
import com.damda.webapp.views.viewmodels.DialogType
import com.damda.webapp.views.viewmodels.MainViewModel
import kotlinx.browser.window
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div

data class FirstInsuranceFormInformation(
    val birthCategory: String = "",
    var childBorn: String = "", // 태아, 어린이
    var gestationWeek: String? = null, // 임신 주수 -> 태아인 경우
    var childMonths: String? = null, // 어린이 나이 -> 어린이인 경우
    var registerInsurance: String = "" // 가입여부 있음, 없음
) {
    private val childBornTitle1 = "태아 (출산전)"
    private val childBornTitle2 = "어린이 (0세 이상)"
    var result = ""

    fun isValidate(): Boolean {
        return birthCategory.isNotBlank()
                && childBorn.isNotBlank()
                && ((childBorn == childBornTitle1 && !gestationWeek.isNullOrBlank())
                || (childBorn == childBornTitle2 && !childMonths.isNullOrBlank()))
                && registerInsurance.isNotBlank()
    }

    fun writeInformation() {
        val jsonString = """
        {
            "태아": "${birthCategory}",
            "태아/어린이 여부": "${childBorn}",
            "임신 주수": "${gestationWeek ?: "어린이여서 등록하지 않음"}",
            "어린이 나이": "${childMonths ?: "태아여서 등록하지 않음"}",
            "태아보험 가입여부": "${registerInsurance}"
        }
        """.trimIndent()

        result = jsonString
    }
}

@Composable
fun StepOneScreen(
    mainViewModel: MainViewModel,
    onScreen: (Screen, FirstInsuranceFormInformation) -> Unit
) {
    StepOneInsuranceForm(mainViewModel, onScreen)
}

@Composable
fun StepOneInsuranceForm(
    mainViewModel: MainViewModel,
    onScreen: (Screen, FirstInsuranceFormInformation) -> Unit
) {
    var firstInsuranceFormInformation by remember { mutableStateOf(FirstInsuranceFormInformation()) }
    val childBornTitle1 = "태아 (출산전)"
    val childBornTitle2 = "어린이 (0세 이상)"

    LaunchedEffect(Unit) {
        window.history.pushState(null, "", "/main")
    }

    FirebaseAnalytics.logPageEntryInComposable(Screen.StepOne.toString())
    FormContainer {
        Div {
            FormTitle("보험료 계산을 위해", "정보를 입력해 주세요.")
            FormSubTitle("예상되는 금액이다보니 참고부탁드립니다.", "실제 설계시 다소 차이가 있을 수 있습니다.")
            LabelTitle(title = "태아", modifier = {
                marginTop(32.px)
            })
            RadioButtonSection(
                firstItemTitle = "단태아",
                secondItemTitle = "다태아",
                clickedItem = firstInsuranceFormInformation.birthCategory,
                onClick = {
                    firstInsuranceFormInformation =
                        firstInsuranceFormInformation.copy(birthCategory = it)
                }
            )
            LabelTitle(title = "태아/어린이 여부", modifier = {
                marginTop(24.px)
            })

            RadioButtonSection(
                firstItemTitle = childBornTitle1,
                secondItemTitle = childBornTitle2,
                clickedItem = firstInsuranceFormInformation.childBorn,
                onClick = {
                    firstInsuranceFormInformation = firstInsuranceFormInformation.copy(childBorn = it)
                    firstInsuranceFormInformation = firstInsuranceFormInformation.copy(gestationWeek = null)
                    firstInsuranceFormInformation = firstInsuranceFormInformation.copy(childMonths = null)
                }
            )
            if (firstInsuranceFormInformation.childBorn == childBornTitle1) {
                LabelTitle(title = "임신 주수", modifier = {
                    marginTop(32.px)
                })

                DropDown(0..60, "주", "임신 주수를 선택해주세요") {
                    firstInsuranceFormInformation = firstInsuranceFormInformation.copy(gestationWeek = it)
                }
            } else if (firstInsuranceFormInformation.childBorn == childBornTitle2) {
                LabelTitle(title = "어린이 개월수", modifier = {
                    marginTop(24.px)
                })
                DropDown(0..48, "개월", "어린이 개월 수를 선택해주세요") {
                    firstInsuranceFormInformation = firstInsuranceFormInformation.copy(childMonths = it)
                }
            }

            LabelTitle(title = "태아보험 가입여부", modifier = {
                marginTop(24.px)
            })
            RadioButtonSection(
                firstItemTitle = "있음",
                secondItemTitle = "없음",
                clickedItem = firstInsuranceFormInformation.registerInsurance,
                onClick = {
                    firstInsuranceFormInformation = firstInsuranceFormInformation.copy(registerInsurance = it)
                    firstInsuranceFormInformation.writeInformation()
                }
            )
        }
        FormNextButton(
            text = "다음",
            isEnabled = firstInsuranceFormInformation.isValidate()
        ) {
            mainViewModel.showDialog(
                dialogType = DialogType.Loading,
                dialogInterface = { isAgree ->
                    onScreen(Screen.StepSecond, firstInsuranceFormInformation)
                }
            )
        }
    }
}