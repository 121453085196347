package com.damda.webapp.views.themes

import androidx.compose.ui.graphics.Color
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgba

fun Color.toCssColor(): CSSColorValue {
    return rgba(
        (this.red * 255).toInt(),
        (this.green * 255).toInt(),
        (this.blue * 255).toInt(),
        this.alpha
    )
}