package com.damda.webapp.views.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.insurance.themes.Colors
import com.damda.webapp.views.core.FirebaseAnalytics
import com.damda.webapp.views.core.dynamicSize
import com.damda.webapp.views.datas.ColumnData
import com.damda.webapp.views.datas.ColumnViewType
import com.damda.webapp.views.datas.TableHeader
import com.damda.webapp.views.datas.bannerRadioButtonPresentation
import com.damda.webapp.views.datas.tableHeaders
import com.damda.webapp.views.layouts.ActionButton
import com.damda.webapp.views.layouts.BannerRadioButtonSections
import com.damda.webapp.views.layouts.Chip
import com.damda.webapp.views.layouts.FormContainer
import com.damda.webapp.views.layouts.JSSpacer
import com.damda.webapp.views.texts.FormTitle
import com.damda.webapp.views.themes.toCssColor
import com.damda.webapp.views.viewmodels.DialogType
import com.damda.webapp.views.viewmodels.MainViewModel
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.StyleScope
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flex
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.minHeight
import org.jetbrains.compose.web.css.minWidth
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.whiteSpace
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Style
import org.jetbrains.compose.web.dom.Table
import org.jetbrains.compose.web.dom.Tbody
import org.jetbrains.compose.web.dom.Td
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.Th
import org.jetbrains.compose.web.dom.Thead
import org.jetbrains.compose.web.dom.Tr

data class SecondInsuranceFormInformation(
    val allColumnDataLists: List<List<ColumnData>> = listOf(
        listOf(
            ColumnData("기본 주 계약", "", 0, false, false, ColumnViewType.CategoryTitle),
            ColumnData("", "", 0, false, false, ColumnViewType.Content),
            ColumnData("", "", 0, false, false, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("1) 상해후유장해", "필수", 0, false, false, ColumnViewType.Title),
            ColumnData("1억원", "", 1790, true, true, ColumnViewType.Content),
            ColumnData("1억원", "", 2590, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("2) 보험료납입면제대상", "필수", 0, false, false, ColumnViewType.Title),
            ColumnData("10만원", "", 14, true, true, ColumnViewType.Content),
            ColumnData("10만원", "", 8, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("3) 보험료납입지원", "필수", 0, false, false, ColumnViewType.Title),
            ColumnData("보험료의 절반", "", 15, true, true, ColumnViewType.Content),
            ColumnData("보험료의 절반", "", 51, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("골절/화상진단", "", 0, false, false, ColumnViewType.CategoryTitle),
            ColumnData("", "", 0, false, false, ColumnViewType.Content),
            ColumnData("", "", 0, false, false, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("1) 골절진단", "", 0, false, false, ColumnViewType.Title),
            ColumnData("5십만원", "", 2674, true, true, ColumnViewType.Content),
            ColumnData("5십만원", "", 3353, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("2) 화상진단", "", 0, false, false, ColumnViewType.Title),
            ColumnData("1백만원", "", 712, true, true, ColumnViewType.Content),
            ColumnData("1백만원", "", 1092, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("수술비", "", 0, false, false, ColumnViewType.CategoryTitle),
            ColumnData("", "", 0, false, false, ColumnViewType.Content),
            ColumnData("", "", 0, false, false, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("1) 상해수술", "", 0, false, false, ColumnViewType.Title),
            ColumnData("5십만원~1천만원", "", 1450, true, true, ColumnViewType.Content),
            ColumnData("5십만원~1천만원", "", 1545, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("2) 질병수술", "", 0, false, false, ColumnViewType.Title),
            ColumnData("5십만원~1천만원", "", 7604, true, true, ColumnViewType.Content),
            ColumnData("5십만원~1천만원", "", 18037, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("입원비", "", 0, false, false, ColumnViewType.CategoryTitle),
            ColumnData("", "", 0, false, false, ColumnViewType.Content),
            ColumnData("", "", 0, false, false, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("1) 상해입원 ", "", 0, false, false, ColumnViewType.Title),
            ColumnData("3만원", "", 816, true, true, ColumnViewType.Content),
            ColumnData("3만원", "", 2850, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("2) 질병입원", "", 0, false, false, ColumnViewType.Title),
            ColumnData("1십만원 - 중환자실 1십만원 포함", "", 11295, true, true, ColumnViewType.Content),
            ColumnData("1십만원 - 중환자실 1십만원 포함", "", 27547, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("진단비", "", 0, false, false, ColumnViewType.CategoryTitle),
            ColumnData("", "", 0, false, false, ColumnViewType.Content),
            ColumnData("", "", 0, false, false, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("1) 암/유사암 ", "", 0, false, false, ColumnViewType.Title),
            ColumnData("1억원/2천만원 ", "", 3470, true, true, ColumnViewType.Content),
            ColumnData("5천만원/1천만원", "", 28582, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("2) 심장", "", 0, false, false, ColumnViewType.Title),
            ColumnData("허혈플랜 2천만원 / 심혈관플랜 1천만원", "", 2726, true, true, ColumnViewType.Content),
            ColumnData("허혈플랜 2천만원 / 심혈관플랜 1천만원", "", 12815, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("태아특약", "", 0, false, false, ColumnViewType.CategoryTitle),
            ColumnData("", "", 0, false, false, ColumnViewType.Content),
            ColumnData("", "", 0, false, false, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("1) 저체중아", "", 0, false, false, ColumnViewType.Title),
            ColumnData(" ", "", 2000, true, true, ColumnViewType.Content),
            ColumnData(" ", "", 2000, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("2) 선천수술", "", 0, false, false, ColumnViewType.Title),
            ColumnData(" ", "", 1000, true, true, ColumnViewType.Content),
            ColumnData(" ", "", 1000, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("3) 뇌성마비,장해출생", "", 0, false, false, ColumnViewType.Title),
            ColumnData(" ", "", 3000, true, true, ColumnViewType.Content),
            ColumnData(" ", "", 3000, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("소아(어린이)/가성비담보", "", 0, false, false, ColumnViewType.CategoryTitle),
            ColumnData("", "", 0, false, false, ColumnViewType.Content),
            ColumnData("", "", 0, false, false, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("1) 어린이소아담보", "", 0, false, false, ColumnViewType.Title),
            ColumnData(" ", "", 3617, true, true, ColumnViewType.Content),
            ColumnData(" ", "", 4625, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("2) 보험료가 저렴한 가성비담보", "", 0, false, false, ColumnViewType.Title),
            ColumnData(" ", "", 1217, true, true, ColumnViewType.Content),
            ColumnData(" ", "", 1315, true, true, ColumnViewType.Content)
        ),
        listOf(
            ColumnData("일상배상책임", "", 0, false, false, ColumnViewType.Title),
            ColumnData(" ", "", 2293, true, true, ColumnViewType.Content),
            ColumnData(" ", "", 2293, true, true, ColumnViewType.Content)
        ),
    )
) {
    var result = ""
    fun sumPrice(selectionNumber: Int): Int {
        return allColumnDataLists.mapNotNull { columnDataList ->
            columnDataList.getOrNull(selectionNumber)
        }.filter { it.isChecked && it.isUseSwitch }.sumOf { it.price }
    }

    fun writeInformation(selectionNumber: Int) {
        val totalPrice = sumPrice(selectionNumber)
        val insuranceType = if (selectionNumber == 1) "30세" else if (selectionNumber == 2) "100세" else "알 수 없음"
        val selectionJson = buildString {
            append("{\n")
            allColumnDataLists.forEachIndexed { index, columnData ->
                val titleColumn = columnData.getOrNull(0)
                if (titleColumn?.columnViewType == ColumnViewType.CategoryTitle) {
                    append("  \"${titleColumn.title}\": [\n")
                } else if (titleColumn?.columnViewType == ColumnViewType.Title) {
                    val selectionColumn = columnData.getOrNull(selectionNumber)

                    val title = titleColumn.title
                    val checkValue = if (selectionColumn?.isChecked == true) "선택" else "비선택"
                    append("    { \"$title\": \"$checkValue\" }")

                    val nextColumList = allColumnDataLists.getOrNull(index + 1)
                    val nextColumnViewType = nextColumList?.getOrNull(0)?.columnViewType
                    if (nextColumnViewType == ColumnViewType.CategoryTitle) {
                        append("]\n,")
                    } else if (nextColumnViewType == ColumnViewType.Title) {
                        append(",\n")
                    } else if (nextColumnViewType == null) {
                        append("]\n }")
                    }
                }
            }
        }

        result = buildString {
            append(
                """
            {
                "보험타입": "$insuranceType",
                "예상보험료": "${totalPrice}원",
                "보장내용들": ${selectionJson}
            }
        """.trimIndent()
            )
        }
    }
}

@Composable
fun StepSecondScreen(
    mainViewModel: MainViewModel,
    onScreen: (Screen, SecondInsuranceFormInformation) -> Unit
) {
    if (mainViewModel.isValidationFirstInsuranceFormInformation()) {
        StepSecondInsuranceForm(mainViewModel, onScreen)
    } else {
        StepSecondInsuranceForm(mainViewModel, onScreen)
        SessionErrorScreen(onScreen)
    }
}

@Composable
fun StepSecondInsuranceForm(
    mainViewModel: MainViewModel,
    onScreen: (Screen, SecondInsuranceFormInformation) -> Unit
) {
    var secondInsuranceFormInformation by remember { mutableStateOf(SecondInsuranceFormInformation()) }
    var selectionNumber by remember { mutableStateOf(1) }
    val sumPrice = mutableStateOf(secondInsuranceFormInformation.sumPrice(selectionNumber))
    val columnSize = 3

    FirebaseAnalytics.logPageEntryInComposable("StepSecond")

    LaunchedEffect(Unit) {
        window.history.pushState(null, "", "/calculator")
    }

    FormContainer {
        FormTitle("30세 vs 100세", "금액을 확인해보세요.")
        BannerRadioButtonSections(bannerRadioButtonPresentation, selectionNumber) {
            selectionNumber = it
        }
        Div({
            style {
                marginTop(16.px)
                marginBottom(16.px)
                justifyContent(JustifyContent.SpaceBetween) // 위아래로 공간을 분배하여 버튼을 하단에 배치
                display(DisplayStyle.Flex) // Flexbox 사용
                flexDirection(FlexDirection.Row) // 세로 방향으로 정렬
                alignItems(AlignItems.Center)
            }
        }) {
            H3({
                style {
                    paddingLeft(8.px)
                    width(100.percent)
                    fontSize(28.px.dynamicSize())
                }
            }) {

                fun formatNumberWithCommas(number: Int): String {
                    return number.toString().reversed().chunked(3).joinToString(",").reversed()
                }

                Text("보험료: ${formatNumberWithCommas(sumPrice.value)}원")
            }
            ActionButton("보험 선택", modifier = {
                width(100.percent)
                height(56.px)
                padding(12.px)
                border(width = 0.px, style = LineStyle.None) // 버튼의 테두리를 없앰
                backgroundColor(Colors.secondaryColor.toCssColor())
                // text css
                color(Color.white)
                fontSize(16.px.dynamicSize())
                fontWeight(700)
                marginBottom(16.px)
            }) {
                mainViewModel.showDialog(
                    dialogType = DialogType.Loading,
                    dialogInterface = { isAgree ->
                        secondInsuranceFormInformation.writeInformation(selectionNumber)
                        onScreen(Screen.StepThird, secondInsuranceFormInformation)
                    }
                )
            }
        }

        Table({
            style {
                width(100.percent)
                property("border-collapse", "collapse")
            }
        }) {
            Thead {
                Tr({
                    style {
                        backgroundColor(Color("#f6f7fb"))
                        property("border-bottom", "1px solid lightgray")
                        property("border-top", "1px solid lightgray")
                    }
                }) {
                    tableHeaders.filterIndexed { index, tableHeader ->
                        if (mainViewModel.windowSize.value == ComposeWindowWidthSizeClass.Small) {
                            index == 0 || index == selectionNumber
                        } else {
                            true
                        }
                    }.forEachIndexed { _, tableHeader ->
                        TitleTh(
                            tableHeader = tableHeader,
                            isSelected = selectionNumber == tableHeader.columnNumber,
                            onClick = {
                                if (it < 0) {
                                    return@TitleTh
                                }
                                selectionNumber = tableHeader.columnNumber
                            }
                        )
                    }
                }


            }
            Tbody {
                secondInsuranceFormInformation.allColumnDataLists.forEach { columnDataList ->
                    Tr({
                        style {
                            property("border-bottom", "1px solid lightgray")
                        }
                    }) {
                        columnDataList.filterIndexed { index, item ->
                            if (mainViewModel.windowSize.value == ComposeWindowWidthSizeClass.Small) {
                                index == 0 || index == selectionNumber
                            } else {
                                true
                            }
                        }.mapIndexed { index, item ->
                            when (item.columnViewType) {
                                ColumnViewType.CategoryTitle -> {
                                    ColumnTitle(
                                        columnNumber = index,
                                        title = item.title,
                                        chipText = item.chipText,
                                        containerStyle = {
                                            fontWeight(900)
                                            fontSize(14.px)
                                            color(Color.black)
                                        }
                                    )
                                }

                                ColumnViewType.Title -> {
                                    ColumnTitle(
                                        columnNumber = index,
                                        title = item.title,
                                        chipText = item.chipText,
                                        containerStyle = {
                                            fontWeight(700)
                                            fontSize(13.px)
                                            color(Color.black)
                                        }
                                    )
                                }

                                else -> {
                                    ColumnContent(
                                        index = index,
                                        selectedNumber = selectionNumber,
                                        size = columnSize,
                                        isLastItem = index == columnDataList.size,
                                        item = item,
                                        containerStyle = {
                                            fontSize(12.px)
                                        },
                                        isFixedButton = columnDataList.getOrNull(0)?.chipText?.isNotBlank() ?: false,
                                        onChangedItem = {
                                            item.isChecked = it
                                            sumPrice.value =
                                                secondInsuranceFormInformation.sumPrice(
                                                    selectionNumber
                                                )
                                        }
                                    )
                                }
                            }
                        }
                    }
                }
            }
            Div(attrs = {
                style {
                    height(32.px)
                }
            })
        }
    }
}

@Composable
fun ColumnTitle(
    columnNumber: Int,
    title: String,
    chipText: String = "",
    containerStyle: StyleScope.() -> Unit = {},
) {
    Td {
        Div({
            style {
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Row) // 가로 방향 정렬
                alignItems(AlignItems.Center) // 세로 가운데 정렬
                justifyContent(JustifyContent.Start) // 가로 왼쪽 정렬
                padding(8.px)
                minHeight(38.px)
                fontSize(14.px.dynamicSize())
                flex(2)
                containerStyle()
                property("border-left", "1px solid lightgray")
            }
        }) {
            Text(title)
            if (chipText.isNotBlank()) {
                Div({
                    style {
                        display(DisplayStyle.Flex)
                        alignItems(AlignItems.Center)
                        whiteSpace("nowrap") // 텍스트가 줄바꿈되지 않도록 설정
                    }
                }) {
                    Chip(chipText)
                }
            }
        }
    }
}

@Composable
fun ColumnContent(
    index: Int,
    selectedNumber: Int,
    size: Int,
    isLastItem: Boolean,
    item: ColumnData,
    isFixedButton: Boolean,
    containerStyle: StyleScope.() -> Unit = {},
    onChangedItem: (Boolean) -> Unit,
) {
    val isUseSwitch = item.isUseSwitch
    val title = item.title
    val chipText = item.chipText

    Td({
        style {
            maxWidth(118.px)
            if (title.isNotEmpty()) {
                property("border-left", "1px solid lightgray")
                property("border-right", "1px solid lightgray")
                if (isLastItem) {
                    property("border-bottom", "1px solid lightgray")
                }
            } else {
                if (index == 0) {
                    property("border-left", "1px solid lightgray")
                } else {
                    property("border-left", "none")
                }

                if (index == size - 1) {
                    property("border-right", "1px solid lightgray")
                } else {
                    property("border-right", "none")
                }

                if (isLastItem) {
                    property("border-bottom", "1px solid lightgray")
                }
            }
            if (selectedNumber == index) {
                // 첫 번째 셀
                property("background-color", "rgba(0, 209, 97, 0.08)")
            } else {
                property("background-color", "white")
            }
        }
    }) {
        Div({
            style {
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Row) // 가로 방향 정렬
                alignItems(AlignItems.Center) // 세로 가운데 정렬
                justifyContent(JustifyContent.End) // 가로 왼쪽 정렬
                padding(8.px)
                minHeight(38.px)
                fontSize(13.px.dynamicSize())
                flex(1)
                containerStyle()
            }
        }) {
            Text(title)
            if (chipText.isNotBlank()) {
                Div {
                    Chip(chipText)
                }
            }
            if (isUseSwitch) {
                Div(
                    {
                        style {
                            width(24.px)
                            height(16.px)
                            marginLeft(8.px)
                            marginRight(8.px)
                        }
                    }
                ) {
                    Switch(
                        isFixedButton = isFixedButton,
                        onChangeSwitch = { changedSwitch -> onChangedItem(changedSwitch) }
                    )
                }
            } else {
                Div()
            }
        }
    }
}

@Composable
fun TitleTh(
    tableHeader: TableHeader,
    isSelected: Boolean,
    onClick: (Int) -> Unit
) {
    Th({
        style {
            maxWidth(75.percent)
            minWidth(64.px)
            height(50.px)
            alignItems(AlignItems.Center)
            justifyContent(JustifyContent.Center)
            thStyle()
            if (isSelected) {
                property("background-color", "rgba(0, 209, 97, 0.08)")
            } else {
                border {
                    width = 0.px
                    style = LineStyle.None
                    color = Color.transparent
                }
                if (tableHeader.columnNumber == 0) {
                    property("border-left", "1px solid lightgray")
                } else if (tableHeader.columnNumber == 3) {
                    property("border-right", "1px solid lightgray")
                }
                property("border-bottom", "1px solid lightgray")
                property("border-top", "1px solid lightgray")
            }
        }
        onClick {
            onClick(tableHeader.columnNumber)
        }
    }) {
        Div({
            style {
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Column)
                alignItems(AlignItems.Center)
                justifyContent(JustifyContent.Center)
            }
        }) {
            if (isSelected) {
                Img("check_mark.svg", attrs = {
                    style {
                        width(20.px)
                        height(20.px)
                    }
                })
            }
            JSSpacer(height = 8.px)
            Text(tableHeader.title)
        }
    }
}

fun StyleScope.thStyle() {
    minHeight(36.px)
    padding(8.px)
    textAlign("center")
    fontSize(14.px.dynamicSize())
}

@Composable
fun Switch(
    onChangeSwitch: (Boolean) -> Unit,
    isFixedButton: Boolean
) {
    Style {
        ".switch" style {
            property("font-size", "17px")
            property("position", "relative")
            property("display", "inline-block")
            property("width", "1.8em")
            property("height", "1.2em")
        }
        ".switch input" style {
            property("opacity", "0")
            property("width", "0px")
            property("height", "0px")
        }
        ".slider" style {
            property("position", "absolute")
            property("cursor", "pointer")
            property("top", "0px")
            property("left", "0px")
            property("right", "0px")
            property("bottom", "0px")
            property("background-color", "rgb(182, 182, 182)")
            property("transition", "0.4s")
            property("border-radius", "16px")
        }
        ".slider:before" style {
            property("position", "absolute")
            property("content", "\"\"")
            property("height", "0.84em")
            property("width", "0.84em")
            property("border-radius", "12px")
            property("left", "0.18em")
            property("bottom", "0.18em")
            property("background-color", "rgb(255, 255, 255)")
            property("transition", "0.4s")
        }
        ".switch input:checked + .slider" style {
            property("background-color", "#21cc4c")
        }
        ".switch input:focus + .slider" style {
            property("box-shadow", "0 0 1px #2196F3")
        }
        ".switch input:checked + .slider:before" style {
            property("transform", "translateX(0.6em)")
        }
    }

    var isChecked by remember { mutableStateOf(true) }
    Label(attrs = {
        classes("switch")
    }) {
        Input(type = InputType.Checkbox, attrs = {
            if (!isFixedButton) {
                onChange {
                    isChecked = it.value
                    onChangeSwitch(it.value)
                }
            }
            checked(isChecked)
        })
        Span(attrs = { classes("slider") })
    }
}