package com.damda.webapp.views.layouts

import androidx.compose.runtime.Composable
import com.damda.insurance.themes.Colors
import com.damda.webapp.views.core.dynamicSize
import com.damda.webapp.views.themes.toCssColor
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.StyleScope
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.bottom
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.columnGap
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.flexGrow
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.right
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun JSFloatingActionButton(onClick: () -> Unit) {
    Div({
        onClick { onClick() }
        style {
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.Center)
            alignItems(AlignItems.Center)
            width(32.px)
            height(32.px)
            backgroundColor(rgb(255, 167, 38))
            color(Color.white)
            borderRadius(50.percent)
            property("box-shadow", "0px 4px 4px rgba(0, 0, 0, 0.25)")
            cursor("pointer")
            position(Position.Absolute)
            bottom(18.px)
            right(18.px)
        }
    }) {
        Text("+")
    }
}

@Composable
fun WidthFullSizeButton(
    title: String,
    onClick: () -> Unit,
    reduceFontSize: CSSSizeValue<CSSUnit.px> = 0.px
) {
    Button(
        attrs = {
            onClick { onClick() }
            style {
                width(100.percent)
                height(70.px)
                backgroundColor(Color("#EC994B"))
                border(0.px)
                color(Color.white)
                fontSize(20.px - reduceFontSize)
                fontWeight("bold")
                padding(12.px)
                borderRadius(30.px)
            }
        }
    ) {
        Text(title)
    }
}

@Composable
fun ActionButton(text: String, modifier: StyleScope.() -> Unit, onClick: () -> Unit) {
    Button(attrs = {
        onClick { onClick() }
        style {
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            justifyContent(JustifyContent.Center)
            backgroundColor(Colors.primaryColor.toCssColor())
            borderRadius(6.px)
            boxSizing("border-box")
            color(Colors.secondaryGreyColor.toCssColor())
            columnGap(4.px)
            cursor("pointer")
            fontSize(14.px.dynamicSize())
            height(56.px)
            position(Position.Relative)
            modifier()
        }
    }) {
        Text(text)
    }
}

@Composable
fun FormNextButton(
    text: String = "다음",
    isEnabled: Boolean,
    onClick: () -> Unit
) {
    Div(attrs = {
        style {
            width(100.percent)
            height(100.percent)
            flexGrow(1.0)
            display(DisplayStyle.Flex)
            alignItems(AlignItems.FlexEnd)
        }
    }) {
        JSSpacer(height = 24.px)
        ActionButton(text, modifier = {
            width(100.percent)
            border(width = 0.px, style = LineStyle.None) // 버튼의 테두리를 없앰
            backgroundColor(if (isEnabled) Colors.secondaryColor.toCssColor() else Colors.secondaryGreyColor.toCssColor())
            // text css
            color(Color.white)
            fontSize(16.px.dynamicSize())
            fontWeight(700)

            marginBottom(16.px)
        }) {
            // 버튼 클릭 시 실행할 동작
            if (isEnabled) {
                onClick()
            }
        }
    }
}