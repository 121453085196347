package com.damda.insurance.networks

import io.ktor.client.HttpClient
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.request.header
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.http.HttpHeaders
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.jsonArray
import kotlinx.serialization.json.jsonObject
import kotlinx.serialization.json.jsonPrimitive

object ApiClient {
    const val EXCEL_URL = "https://script.google.com/macros/s/AKfycbxq97qTdxzwVxAlXHAv1lrjBxcNiovGbnjOepmKb3-2gbtSf1kwLBy_J0pyPMxRajE/exec"
    // HTTP 클라이언트 설정
    private val client = HttpClient {
        install(ContentNegotiation) {
            json(Json { ignoreUnknownKeys = true })
        }
    }

    suspend fun postData(url: String, requestBody: String, logLogic: (String) -> Unit) {
        if (!validateInput(requestBody, logLogic)) {
            throw IllegalArgumentException("Invalid input data")
        }
        client.post(url) {
            header(HttpHeaders.ContentType, "text/plain;charset=utf-8")
            setBody(requestBody)
        }
    }

    private fun validateInput(requestBody: String, logLogic: (String) -> Unit): Boolean {
        return try {
            val jsonData = Json.parseToJsonElement(requestBody).jsonObject

            // Check for page1
            val page1 = jsonData["page1"]?.jsonObject ?: return false
            if (!validatePage1(page1)) {
                logLogic(page1.toString())
                return false
            }

            // Check for page2
            val page2 = jsonData["page2"]?.jsonObject ?: return false
            if (!validatePage2(page2)) {
                logLogic(page2.toString())
                return false
            }

            // Check for page3
            val page3 = jsonData["page3"]?.jsonObject ?: return false
            if (!validatePage3(page3)) {
                logLogic(page3.toString())
                return false
            }

            true
        } catch (e: Exception) {
            false
        }
    }

    private fun validatePage1(page1: JsonObject): Boolean {
        return page1["태아"]?.jsonPrimitive?.isString == true &&
                page1["태아/어린이 여부"]?.jsonPrimitive?.isString == true &&
                page1["임신 주수"]?.jsonPrimitive?.isString == true &&
                page1["어린이 나이"]?.jsonPrimitive?.isString == true &&
                page1["태아보험 가입여부"]?.jsonPrimitive?.isString == true
    }

    private fun validatePage2(page2: JsonObject): Boolean {
        val pageData = page2["보장내용들"]?.jsonObject ?: return false
        return page2["보험타입"]?.jsonPrimitive?.isString == true &&
                page2["예상보험료"]?.jsonPrimitive?.isString == true &&
                validateCoverage(pageData)
    }

    private fun validateCoverage(coverage: JsonObject): Boolean {
        val keys = listOf(
            "기본 주 계약", "골절/화상진단", "수술비", "입원비", "진단비", "태아특약", "소아(어린이)/가성비담보"
        )
        return keys.all { key ->
            coverage[key]?.jsonArray?.all { it.jsonObject.values.all { item -> item.jsonPrimitive.isString } } == true
        }
    }

    private fun validatePage3(page3: JsonObject): Boolean {
        return page3["이름"]?.jsonPrimitive?.isString == true &&
                page3["핸드폰번호"]?.jsonPrimitive?.isString == true &&
                page3["주소"]?.jsonPrimitive?.isString == true &&
                page3["상담 가능한 시간대"]?.jsonPrimitive?.isString == true &&
                page3["개인정보보호 동의"]?.jsonPrimitive?.isString == true
    }
}