package com.damda.webapp.views.datas

val bannerRadioButtonPresentation = listOf(
    BannerRadioButtonPresentation(
        leftTopTitle = "30세",
        rightTopTitle = "환급 X",
        leftBottomTitle = "저렴한 보험을 원하시면",
        rightBottomTitle = "",
        isRecommend = false,
        number = 1
    ),
    BannerRadioButtonPresentation(
        leftTopTitle = "100세",
        rightTopTitle = "환급 O",
        leftBottomTitle = "환급도 되고 긴 보장을 원하시면",
        rightBottomTitle = "",
        isRecommend = true,
        number = 2
    )
)

data class BannerRadioButtonPresentation(
    val leftTopTitle: String,
    val rightTopTitle: String,
    val leftBottomTitle: String,
    val rightBottomTitle: String,
    val number: Int,
    val isRecommend: Boolean
)