package com.damda.webapp.views.layouts

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.maxHeight
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.dom.Div

@Composable
fun FormContainer(content: @Composable () -> Unit,) {
    Div(attrs = {
        style {
            paddingLeft(24.px)
            paddingRight(24.px)
            height(100.vh - 64.px) // appbar 길이를 줄이려고
            maxHeight(100.vh - 64.px)
            position(Position.Relative)
            display(DisplayStyle.Flex) // Flexbox 사용
            flexDirection(FlexDirection.Column) // 세로 방향으로 정렬
            justifyContent(JustifyContent.SpaceBetween) // 위아래로 공간을 분배하여 버튼을 하단에 배치
        }
    }) {
        content()
    }
}