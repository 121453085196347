package com.damda.webapp.views.layouts

import androidx.compose.runtime.Composable
import com.damda.insurance.themes.Colors
import com.damda.webapp.views.core.dynamicSize
import com.damda.webapp.views.themes.toCssColor
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun Chip(title: String) {
    Span ({
        style {
            marginLeft(8.px)
            color(Color.green)
            fontSize(12.px.dynamicSize())
            borderRadius(4.px)
            border(0.75.px, LineStyle.Solid, Colors.primaryColor.toCssColor())
            paddingLeft(6.px)
            paddingRight(6.px)
            paddingTop(4.px)
            paddingBottom(4.px)
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            justifyContent(JustifyContent.Center)
        }
    }) {
        Text(title)
    }
}

@Composable
fun FilledChip(
    text: String,
    backgroundColor: CSSColorValue,
    contentColor: CSSColorValue
) {
    Span({
        style {
            backgroundColor(backgroundColor)
            color(contentColor)
            paddingLeft(8.px)
            paddingRight(8.px)
            paddingTop(4.px)
            paddingBottom(4.px)
            borderRadius(12.px)
            fontSize(12.px.dynamicSize())
        }
    }) {
        Text(text)
    }
}