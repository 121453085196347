package com.damda.webapp.views.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.webapp.views.core.FirebaseAnalytics
import com.damda.webapp.views.layouts.DropDown
import com.damda.webapp.views.layouts.FormContainer
import com.damda.webapp.views.layouts.FormNextButton
import com.damda.webapp.views.layouts.RadioButtonSection
import com.damda.webapp.views.layouts.SimpleInput
import com.damda.webapp.views.texts.FormSubTitle
import com.damda.webapp.views.texts.FormTitle
import com.damda.webapp.views.texts.LabelTitle
import com.damda.webapp.views.viewmodels.DialogType
import com.damda.webapp.views.viewmodels.MainViewModel
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div

data class ThirdInsuranceFormInformation(
    var name: String = "",
    val phoneNumber: String = "",
    val address: String = "",
    val availableTime: String = "",
    val checkPrivacy: String = ""
) {
    var result = ""
    fun isValidate(): Boolean {
        return name.isNotBlank() && phoneNumber.isNotBlank() && address.isNotBlank() && availableTime.isNotBlank() && checkPrivacy == "동의"
    }

    fun writeInformation() {
        val jsonString = """
        {
            "이름": "${name}",
            "핸드폰번호": "${phoneNumber}",
            "주소": "${address}",
            "상담 가능한 시간대": "${availableTime}",
            "개인정보보호 동의": "${checkPrivacy}"
        }
        """.trimIndent()

        result = jsonString
    }
}

@Composable
fun StepThirdScreen(
    mainViewModel: MainViewModel,
    onScreen: (Screen, ThirdInsuranceFormInformation) -> Unit
) {
    if (mainViewModel.isValidationSecondInsuranceFormInformation()) {
        StepThirdInsuranceForm(mainViewModel, onScreen)
    } else {
        StepThirdInsuranceForm(mainViewModel, onScreen)
    }
}

@Composable
fun StepThirdInsuranceForm(
    mainViewModel: MainViewModel,
    onScreen: (Screen, ThirdInsuranceFormInformation) -> Unit
) {
    var insuranceFormInformation by remember { mutableStateOf(ThirdInsuranceFormInformation()) }
    val availableTimeList = listOf("평일 오전", "평일 오후", "주말 오전", "주말 오후", "시간상관없음")

    FirebaseAnalytics.logPageEntryInComposable("StepThird")
    LaunchedEffect(Unit) {
        window.history.pushState(null, "", "/complete")
    }
    FormContainer {
        Div {
            FormTitle("고객님의 정보를", "입력해 주세요")
            FormSubTitle("보험 상담을 하기 위한 용도로 ", "상담 진행 후에는 개인 정보를 파기합니다.")

            Div({
                style {
                    display(DisplayStyle.Flex)
                    flexDirection(FlexDirection.Row)
                    marginTop(32.px)
                }
            }) {
                Div({
                    style {
                        width(100.percent)
                        marginRight(8.px)
                    }
                }) {
                    LabelTitle(title = "이름") {

                    }
                    SimpleInput(
                        hint = "예) 홍길동",
                        maxLength = 8,
                        inputType = InputType.Text
                    ) { changedName ->
                        insuranceFormInformation = insuranceFormInformation.copy(name = changedName)
                    }
                }

                Div({
                    style {
                        width(100.percent)
                        marginLeft(8.px)
                    }
                }) {
                    LabelTitle(title = "핸드폰 번호") {

                    }
                    SimpleInput(
                        hint = "예) 01011112222",
                        maxLength = 11,
                        inputType = InputType.Tel
                    ) { phoneNumber ->
                        insuranceFormInformation = insuranceFormInformation.copy(phoneNumber = phoneNumber)
                    }
                }
            }

            LabelTitle(title = "주소") {
                marginTop(32.px)
            }
            SimpleInput(
                hint = "예) 서울시 동작구 신대방1동",
                maxLength = 99,
                inputType = InputType.Text
            ) { address ->
                insuranceFormInformation = insuranceFormInformation.copy(address = address)
            }


            LabelTitle(title = "상담 가능한 시간대를 입력해주세요") {
                marginTop(32.px)
            }
            DropDown(availableTimeList, "상담 가능한 시간을 선택해주세요") {
                insuranceFormInformation = insuranceFormInformation.copy(availableTime = it ?: "미선택")
            }

            LabelTitle(title = "개인정보보호 동의") {
                marginTop(32.px)
            }
            RadioButtonSection("동의", "비동의", insuranceFormInformation.checkPrivacy, onClick = {
                mainViewModel.showDialog(
                    DialogType.Privacy,
                    dialogInterface = { isAgree ->
                        insuranceFormInformation = insuranceFormInformation.copy(checkPrivacy = if (isAgree) "동의" else "비동의")
                    }
                )
            })
        }

        FormNextButton(text = "상담 요청", isEnabled = insuranceFormInformation.isValidate()) {
            mainViewModel.showDialog(
                dialogType = DialogType.Loading,
                dialogInterface = { isAgree ->
                    insuranceFormInformation.writeInformation()
                    onScreen(Screen.Complete, insuranceFormInformation)
                }
            )
        }
    }

}