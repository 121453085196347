package com.damda.webapp.views.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.insurance.themes.Colors
import com.damda.webapp.views.core.dynamicSize
import com.damda.webapp.views.themes.toCssColor
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.style
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Option
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Select
import org.jetbrains.compose.web.dom.Text

@Composable
fun DropDown(
    range: IntRange,
    textLabel: String,
    errorMessage: String,
    onSelectItem: (String?) -> Unit
) {
    var state by remember { mutableStateOf<InputState>(InputState.Error) }

    Div {
        Select(attrs = {
            id("dropdown")
            onChange { event ->
                state = if (event.value == "none") {
                    InputState.Empty
                } else {
                    InputState.Confirm
                }
                onSelectItem(event.value)
            }
            style {
                width(100.percent)
                height(56.px)
                padding(8.px)
                paddingRight(16.px)
                border {
                    style(LineStyle.Solid)
                    val borderColor = when (state) {
                        is InputState.Error -> Colors.errorColor.toCssColor()
                        is InputState.Confirm -> Colors.primaryColor.toCssColor()
                        is InputState.Empty -> Colors.secondaryGreyColor.toCssColor()
                    }
                    color(borderColor)
                    width(1.25.px)
                }
                borderRadius(4.px)
                fontSize(14.px.dynamicSize())
            }
        }) {
            Option(value = "none") {
                Text("선택")
            }
            for (rangeNumber in range) {
                Option(value = rangeNumber.toString()) { Text("${rangeNumber}${textLabel}") }
            }
        }
        if (state is InputState.Error) {
            P(attrs = {
                style {
                    marginTop(4.px)
                    color(Colors.errorColor.toCssColor())
                    fontSize(12.px.dynamicSize())
                }
            }) {
                Text(errorMessage)
            }
        }
    }
}

@Composable
fun DropDown(
    items: List<String>,
    errorMessage: String,
    onSelectItem: (String?) -> Unit
) {
    var state by remember { mutableStateOf<InputState>(InputState.Error) }

    Div {
        Select(attrs = {
            id("dropdown")
            onChange { event ->
                state = if (event.value == "none") {
                    InputState.Empty
                } else {
                    InputState.Confirm
                }
                onSelectItem(event.value)
            }
            style {
                width(100.percent)
                height(56.px)
                padding(8.px)
                paddingRight(16.px)
                border {
                    style(LineStyle.Solid)
                    val borderColor = when (state) {
                        is InputState.Error -> Colors.errorColor.toCssColor()
                        is InputState.Confirm -> Colors.primaryColor.toCssColor()
                        is InputState.Empty -> Colors.secondaryGreyColor.toCssColor()
                    }
                    color(borderColor)
                    width(1.25.px)
                }
                borderRadius(4.px)
                fontSize(14.px.dynamicSize())
            }
        }) {
            Option(value = "none") {
                Text("선택")
            }
            items.forEach {
                Option(value = it) { Text(it) }
            }
        }
        if (state is InputState.Error) {
            P(attrs = {
                style {
                    marginTop(4.px)
                    color(Colors.errorColor.toCssColor())
                    fontSize(12.px.dynamicSize())
                }
            }) {
                Text(errorMessage)
            }
        }
    }
}