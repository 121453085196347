package com.damda.insurance.themes

import androidx.compose.ui.graphics.Color

object Colors {
    // Primary colors
    val primaryColor = Color(31, 158, 73)
    val primaryVariantColor = Color(0xFF1A1821)
    val secondaryColor = Color(0xFF00b855)
    val secondaryVariantColor = Color(0xFF603544)

    // Background and surface colors
    val backgroundColor = Color(255, 255, 255)
    val surfaceColor = Color(0xFF7A6F7E)
    val errorColor = Color(0xFFff455b)
    val primaryGreyColor = Color(0xFF222222)
    val secondaryGreyColor = Color(0xFF5a6375)
    val cardPrimaryColor = Color(0xFFf0c8c8)

    // text Colors
    val primaryTextColor = Color(0xFF222222)
    val secondaryTextColor = Color(0xFF5a6375)

    // On colors
    val onPrimaryColor = Color(0xFFDBD5DF)
    val onSecondaryColor = Color(0xFF1e192a)
    val onTertiaryColor = Color(0xFFEDB6C6)
    val onErrorColor = Color(0xFF300C16)
    val onPrimaryContainer = Color(0xFFC6BFCC)
    val onSecondaryContainer = Color(0xFF342B37)
    val onTertiaryContainer = Color(0xFFF9DDE0)
    val onErrorContainer = Color(0xFF040404)
    val surfaceVariant = Color(0xFFCCBEF8)
    val onSurfaceVariant = Color(0xFF46454f)

    // Additional colors if needed
    val primaryContainer = Color(0xFF47405C)
    val secondaryContainer = Color(0xFF615879)
    val tertiaryContainer = Color(0xFF1A0D41)
    val outlineVariant = Color(0xFFc6c4cf)
}