package com.damda.webapp.views.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import com.damda.webapp.views.dialogs.LoadingDialog
import com.damda.webapp.views.dialogs.PrivacyDialog
import com.damda.webapp.views.dialogs.PrivacyFullScreenDialog
import com.damda.webapp.views.layouts.AppBar
import com.damda.webapp.views.layouts.JSLargeBanner
import com.damda.webapp.views.layouts.JSSpacer
import com.damda.webapp.views.viewmodels.DialogType
import com.damda.webapp.views.viewmodels.MainViewModel
import kotlinx.browser.window
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.maxHeight
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.events.Event

enum class ComposeWindowWidthSizeClass {
    Small,
    Medium,
    Large
}

@Composable
fun MainWebScreen(
    mainViewModel: MainViewModel = MainViewModel()
) {
    val dialogState by remember { mainViewModel.dialogManageState }
    val windowSize by remember { mainViewModel.windowSize }
    val screen by remember { mainViewModel.screen }

    DisposableEffect(Unit) {
        val resizeListener: (Event) -> Unit = {
            val newWindowSize = when {
                window.innerWidth < 720 -> ComposeWindowWidthSizeClass.Small
                window.innerWidth < 1200 -> ComposeWindowWidthSizeClass.Medium
                else -> ComposeWindowWidthSizeClass.Large
            }
            mainViewModel.updateWindowSize(newWindowSize)
        }

        window.addEventListener("resize", resizeListener)
        resizeListener(Event(""))

        onDispose {
            window.removeEventListener("resize", resizeListener)
        }
    }

    Div(
        attrs = {
            style {
                width(100.percent)
                height(100.vh)
                maxHeight(100.vh)
            }
        }
    ) {
        AppBar(
            "",
            leftIcon = "logo.png",
            leftIconModifier = { width(32.px); height(32.px); },
            isShowLeftIcon = true,
            onClickLeftIcon = {},
        )
        Div({
            style {
                display(DisplayStyle.Flex)
                width(100.percent)
            }
        }) {
            if (windowSize == ComposeWindowWidthSizeClass.Small) {
                InsuranceArea(windowSize, screen, mainViewModel)
            } else {
                BannerArea()
                InsuranceArea(windowSize, screen, mainViewModel)
            }
        }

        if (dialogState.isShow) {
            when (dialogState.dialogType) {
                DialogType.Privacy -> {
                    if (windowSize == ComposeWindowWidthSizeClass.Small) {
                        PrivacyFullScreenDialog {
                            mainViewModel.processDialogInterfaceData(it)
                            mainViewModel.hideDialog()
                        }
                    } else {
                        PrivacyDialog {
                            mainViewModel.processDialogInterfaceData(it)
                            mainViewModel.hideDialog()
                        }
                    }
                }

                DialogType.Loading -> {
                    LoadingDialog {
                        mainViewModel.processDialogInterfaceData(it)
                        mainViewModel.hideDialog()
                    }
                }

                DialogType.LoadingCalculator -> {
                    LoadingDialog(
                        fileName = "calculator.gif",
                        duration = 3000..4000
                    ) {
                        mainViewModel.processDialogInterfaceData(it)
                        mainViewModel.hideDialog()
                    }
                }

                else -> {

                }
            }
        }
    }
}

@Composable
fun BannerArea() {
    Div({
        style {
            width(100.percent)
            height(100.percent - 64.px)
            paddingLeft(16.px)
            paddingRight(16.px)
            paddingTop(24.px)
        }
    }) {
        BannerList()
    }
}

@Composable
fun BannerList() {
    JSLargeBanner(
        72.px,
        "baby_socks.svg",
        "태아보험 상담이벤트",
        "상담/점검 받아보고 이벤트 상품 받기",
        "",
        cardColor = Color("#FCE4EC"),
        onClick = {
            window.open("https://naver.me/FfecPlks", "_blank")
        }
    )
    JSSpacer(height = 12.px)
    JSLargeBanner(
        72.px,
        "baby_clothes.svg",
        "핫딜 정보가 궁금하다면?",
        "실제 운영하고 있는 핫딜공유방",
        "",
        cardColor = Color("#E0F7FA"),
        onClick = {
            window.open("https://open.kakao.com/o/gBC0M9Cf", "_blank")
        }
    )
    JSSpacer(height = 12.px)
    JSLargeBanner(
        72.px,
        "party.svg",
        "응애톡 계산기 친구 초대",
        "친구초대하고 상담/점검받으면 사은품발송",
        "",
        cardColor = Color("#E8F5E9"),
        onClick = {
            window.open("https://naver.me/FHlbvRHv", "_blank")
        }
    )
    JSSpacer(height = 12.px)
    JSLargeBanner(
        72.px,
        "pet_icon.svg",
        "펫보험 궁금하시다면?",
        "질문서 작성해주시면 설계안을 보내드립니다.",
        "",
        cardColor = Color("#fff8e1"),
        onClick = {
            window.open("https://naver.me/FzQcuBLd", "_blank")
        }
    )
    JSSpacer(height = 8.px)
}

@Composable
fun InsuranceArea(
    windowSize: ComposeWindowWidthSizeClass,
    screen: Screen,
    mainViewModel: MainViewModel
) {
    Div({
        style {
            width(100.percent)
            height(100.percent - 64.px)
        }
    }) {
        when (screen) {
            Screen.StepOne -> {
                StepOneScreen(mainViewModel) { changedScreen, formInformation ->
                    mainViewModel.updateScreen(changedScreen)
                    mainViewModel.updateFirstItem(formInformation)
                }
            }

            Screen.StepSecond -> {
                StepSecondScreen(mainViewModel) { changedScreen, formInformation ->
                    mainViewModel.updateScreen(changedScreen)
                    mainViewModel.updateSecondItem(formInformation)
                }
            }

            Screen.StepThird -> {
                StepThirdScreen(mainViewModel) { changedScreen, formInformation ->
                    mainViewModel.updateScreen(changedScreen)
                    mainViewModel.updateFourthItem(formInformation)
                }
            }

            Screen.Complete -> {
                StepCompleteScreen(windowSize, mainViewModel)
            }
        }
    }
}

