package com.damda.webapp.views.datas

data class ColumnData(
    val title: String,
    val chipText: String,
    val price: Int,
    var isChecked: Boolean,
    var isUseSwitch: Boolean,
    val columnViewType: ColumnViewType
)

enum class ColumnViewType {
    CategoryTitle, Title, Content;
}