package com.damda.webapp.views.dialogs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.w3c.dom.HTMLImageElement

@Composable
fun LoadingDialog(
    duration: IntRange = 1000 .. 2000,
    fileName: String = "loadingbar.gif",
    onCompleteLoading: (Boolean) -> Unit
) {
    var imageLoaded by remember { mutableStateOf(false) }

    LaunchedEffect(imageLoaded) {
        // Delay for a random time between 1 to 2 seconds
        if (imageLoaded) {
            val delayTime = (duration).random().toLong()
            delay(delayTime)
            onCompleteLoading(true)
        }
    }

    Div({
        style {
            display(DisplayStyle.Flex)
            flexDirection(FlexDirection.Column)
            alignItems(AlignItems.Center)
            justifyContent(JustifyContent.Center)
            position(Position.Fixed)
            top(0.px)
            left(0.px)
            width(100.percent)
            height(100.percent)
            backgroundColor(rgba(0, 0, 0, 0.5))
        }
    }) {
        Div({
            style {
                backgroundColor(Color.white)
                padding(16.px)
                borderRadius(24.px)
                maxWidth(256.px)
                width(100.percent)
                height(182.px)
                property("text-align", "left")
            }
        }) {
            Div({
                style {
                    width(100.percent)
                    height(100.percent)
                    display(DisplayStyle.Flex)
                    justifyContent(JustifyContent.Center)
                    alignItems(AlignItems.Center)
                    padding(8.px)
                }
            }) {
                Img(fileName, attrs = {
                    ref {
                        (it as? HTMLImageElement)?.onload = {
                            imageLoaded = true
                            onDispose {
                                imageLoaded = false
                            }
                        }
                        onDispose {
                            imageLoaded = false
                            (it as? HTMLImageElement)?.onload = null
                        }
                    }

                    style {
                        width(64.px)
                    }

                })
            }
        }
    }
}