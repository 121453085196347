package com.damda.webapp.views.screens

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.damda.webapp.views.core.FirebaseAnalytics
import com.damda.webapp.views.layouts.FormContainer
import com.damda.webapp.views.layouts.FormNextButton
import com.damda.webapp.views.layouts.JSSpacer
import com.damda.webapp.views.texts.FormSubTitle
import com.damda.webapp.views.texts.FormTitle
import kotlinx.browser.window
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img

@Composable
fun SessionErrorScreen(onScreen: (Screen, TempInsuranceFormInformation) -> Unit) {
    FirebaseAnalytics.logPageEntryInComposable("StepError")
    LaunchedEffect(Unit) {
        window.history.pushState(null, "", "/error")
    }
    FormContainer {
        Div {
            FormTitle("접속 오류 발생", "다시 시도해주세요.")
            JSSpacer(height = 12.px)
            Img("error_icon.png", attrs = {
                style {
                    width(250.px)
                    height(250.px)
                }
            })
            JSSpacer(height = 24.px)
            FormSubTitle("오랜 시간이 지나서 정보가 소실되었습니다.", "처음부터 다시 실행해주시면 감사하겠습니다.")
        }

        FormNextButton(
            text = "다시 시도하기",
            isEnabled = true) {
            onScreen(Screen.StepSecond, TempInsuranceFormInformation())
        }
    }
}

@Composable
fun SessionErrorScreen(onScreen: (Screen, SecondInsuranceFormInformation) -> Unit) {
    FirebaseAnalytics.logPageEntryInComposable("StepError")
    FormContainer {
        Div {
            FormTitle("접속 오류 발생", "다시 시도해주세요.")
            JSSpacer(height = 12.px)
            Img("error_icon.png", attrs = {
                style {
                    width(250.px)
                    height(250.px)
                }
            })
            JSSpacer(height = 24.px)
            FormSubTitle("오랜 시간이 지나서 정보가 소실되었습니다.", "처음부터 다시 실행해주시면 감사하겠습니다.")
        }

        FormNextButton(
            text = "다시 시도하기",
            isEnabled = true) {
            onScreen(Screen.StepSecond, SecondInsuranceFormInformation())
        }
    }
}

@Composable
fun SessionErrorScreen(onScreen: (Screen, ThirdInsuranceFormInformation) -> Unit) {
    FormContainer {
        Div {
            FormTitle("접속 오류 발생", "다시 시도해주세요.")
            FormSubTitle("오랜 시간이 지나서 정보가 소실되었습니다.", "처음부터 다시 실행해주시면 감사하겠습니다.")
        }
        FormNextButton(
            text = "다시 시도하기",
            isEnabled = true) {
            onScreen(Screen.StepSecond, ThirdInsuranceFormInformation())
        }
    }
}