package com.damda.webapp.views.viewmodels

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateOf
import com.damda.webapp.views.screens.ComposeWindowWidthSizeClass
import com.damda.webapp.views.screens.FirstInsuranceFormInformation
import com.damda.webapp.views.screens.Screen
import com.damda.webapp.views.screens.SecondInsuranceFormInformation
import com.damda.webapp.views.screens.ThirdInsuranceFormInformation

sealed class DialogType {
    data object Privacy : DialogType()
    data object Loading : DialogType()
    data object LoadingCalculator : DialogType()
    data object Empty : DialogType()

}
data class DialogManageState(
    val isShow: Boolean = false,
    val dialogInterface: ((Boolean) -> Unit)? = null,
    val dialogType: DialogType = DialogType.Empty
)

class MainViewModel {
    private var firstInsuranceFormInformation: MutableState<FirstInsuranceFormInformation?> = mutableStateOf(null)
    private var secondInsuranceFormInformation: MutableState<SecondInsuranceFormInformation?> = mutableStateOf(null)
    private var thirdInsuranceFormInformation: MutableState<ThirdInsuranceFormInformation?> = mutableStateOf(null)

    private var _dialogManageState = mutableStateOf(DialogManageState())
    val dialogManageState: State<DialogManageState> = _dialogManageState

    private var _windowSize = mutableStateOf(ComposeWindowWidthSizeClass.Small)
    val windowSize: State<ComposeWindowWidthSizeClass> = _windowSize

    private val _screen: MutableState<Screen> = mutableStateOf(Screen.StepOne)
    val screen: State<Screen> = _screen

    fun getAllResult(): String {
        return """
        {
            "page1": ${firstInsuranceFormInformation.value?.result},
            "page2": ${secondInsuranceFormInformation.value?.result},
            "page3": ${thirdInsuranceFormInformation.value?.result}
        }
        """.trimIndent()
    }

    fun isValidationFirstInsuranceFormInformation(): Boolean {
        return firstInsuranceFormInformation.value != null
    }

    fun isValidationSecondInsuranceFormInformation(): Boolean {
        return firstInsuranceFormInformation.value != null && isValidationFirstInsuranceFormInformation()
    }

    fun updateScreen(newScreen: Screen) {
        _screen.value = newScreen
    }

    fun updateWindowSize(newWindowSize: ComposeWindowWidthSizeClass) {
        _windowSize.value = newWindowSize
    }

    fun updateFirstItem(insuranceFormInformation: FirstInsuranceFormInformation) {
        firstInsuranceFormInformation.value = insuranceFormInformation
    }

    fun updateSecondItem(insuranceFormInformation: SecondInsuranceFormInformation) {
        secondInsuranceFormInformation.value = insuranceFormInformation
    }

    fun updateFourthItem(insuranceFormInformation: ThirdInsuranceFormInformation) {
        thirdInsuranceFormInformation.value = insuranceFormInformation
    }

    fun showDialog(dialogType: DialogType, dialogInterface: ((Boolean) -> Unit)) {
        _dialogManageState.value = dialogManageState.value.copy(isShow = true, dialogInterface = dialogInterface, dialogType = dialogType)
    }

    fun hideDialog() {
        _dialogManageState.value = dialogManageState.value.copy(isShow = false)
    }

    // 로직이 종료된 후에 데이터 넘기는거, 현재는 개인정보에서만 쓰임
    fun processDialogInterfaceData(isChecked: Boolean) {
        dialogManageState.value.dialogInterface?.invoke(isChecked)
    }
}