package com.damda.webapp.views.layouts

import androidx.compose.foundation.Image
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.insurance.themes.Colors
import com.damda.webapp.views.core.dynamicSize
import com.damda.webapp.views.themes.toCssColor
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.maxLength
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.right
import org.jetbrains.compose.web.css.style
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

sealed class InputState {
    data object Empty : InputState()
    data object Error : InputState()
    data object Confirm : InputState()
}

@Composable
fun BirthdayInputSection(
    clickedItem: String,
    onClick: (String) -> Unit,
    onChangeBirthday: (String) -> Unit
) {
    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            gap(16.px)
            width(100.percent)
        }
    }) {
        // Birthday Input
        BirthdayInput(onChangeBirthday)
        RadioButtonSection("남자", "여자", clickedItem, onClick)
    }
}

@Composable
fun BirthdayInput(
    onChangeBirthday: (String) -> Unit
) {
    var birthday by remember { mutableStateOf("") }
    var state by remember { mutableStateOf<InputState>(InputState.Empty) }
    var errorMessage by remember { mutableStateOf("") }

    fun validateBirthday(input: String): Boolean {
        if (input.length != 8) return false
        val year = input.substring(0, 4).toIntOrNull() ?: return false
        val month = input.substring(4, 6).toIntOrNull() ?: return false
        val day = input.substring(6, 8).toIntOrNull() ?: return false

        if (month !in 1..12 || day !in 1..31) return false
        if (month == 2 && day > 29) return false
        if ((month == 4 || month == 6 || month == 9 || month == 11) && day > 30) return false

        return true
    }

    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            gap(12.px)
            position(Position.Relative)
            width(75.percent)
        }
    }) {
        Div(attrs = {
            style {
                width(100.percent)
            }
        }) {
            Input(type = InputType.Text, attrs = {
                value(birthday)
                onInput {
                    val input = it.value.filter { char -> char.isDigit() }.take(8)
                    birthday = input
                    state = when {
                        input.isEmpty() -> InputState.Empty
                        input.length != 8 || !validateBirthday(input) -> InputState.Error
                        else -> InputState.Confirm
                    }
                    errorMessage = when (state) {
                        is InputState.Empty -> ""
                        is InputState.Error -> "생년월일 8자리를 정확히 입력해 주세요."
                        else -> ""
                    }
                    if (state == InputState.Confirm) {
                        onChangeBirthday(input)
                    } else {
                        onChangeBirthday("")
                    }
                }
                placeholder("예)20000101")
                maxLength(8)
                style {
                    padding(12.px)
                    border {
                        style(LineStyle.Solid)
                        color(when (state) {
                            is InputState.Error -> Colors.errorColor.toCssColor()
                            is InputState.Confirm -> Colors.primaryColor.toCssColor()
                            is InputState.Empty -> Colors.secondaryGreyColor.toCssColor()
                        })
                        width(1.25.px)
                    }
                    height(56.px)
                    borderRadius(6.px)
                    boxSizing("border-box")
                    fontSize(14.px.dynamicSize())
                    width(100.percent)
                }
            })
            if (state is InputState.Error) {
                Span(attrs = {
                    style {
                        position(Position.Absolute)
                        right(12.px)
                        top(16.px)
                        color(Colors.errorColor.toCssColor())
                        display(DisplayStyle.Flex)
                        alignItems(AlignItems.Center)
                        justifyContent(JustifyContent.Center)
                    }
                }) {
                    Img("error_mark.png", attrs = {
                        style {
                            width(24.px)
                            height(24.px)
                        }
                    })
                }
            }
            if (state is InputState.Error) {
                P(attrs = {
                    style {
                        marginTop(4.px)
                        color(Colors.errorColor.toCssColor())
                        fontSize(12.px.dynamicSize())
                    }
                }) {
                    Text(errorMessage)
                }
            }
        }
    }
}

@Composable
fun registrationNumberInput(
    onChangeNumber: (String) -> Unit
) {
    var registrationNumber by remember { mutableStateOf("") }
    var state by remember { mutableStateOf<InputState>(InputState.Empty) }
    var errorMessage by remember { mutableStateOf("") }

    fun validateBirthday(input: String): Boolean {
        if (input.length != 6) return false
        val year = input.substring(0, 2).toIntOrNull() ?: return false
        val month = input.substring(2, 4).toIntOrNull() ?: return false
        val day = input.substring(4, 6).toIntOrNull() ?: return false

        if (month !in 1..12 || day !in 1..31) return false
        if (month == 2 && day > 29) return false
        if ((month == 4 || month == 6 || month == 9 || month == 11) && day > 30) return false

        return true
    }

    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            gap(12.px)
            position(Position.Relative)
            width(100.percent)
        }
    }) {
        Div(attrs = {
            style {
                width(100.percent)
            }
        }) {
            Input(type = InputType.Text, attrs = {
                value(registrationNumber)
                onInput {
                    val input = it.value.filter { char -> char.isDigit() }.take(6)
                    registrationNumber = input
                    state = when {
                        input.isEmpty() -> InputState.Empty
                        input.length != 6 || !validateBirthday(input) -> InputState.Error
                        else -> InputState.Confirm
                    }
                    errorMessage = when (state) {
                        is InputState.Empty -> ""
                        is InputState.Error -> "주민등록번호 6자리를 입력해주세요."
                        else -> ""
                    }
                    if (state == InputState.Confirm) {
                        onChangeNumber(input)
                    } else {
                        onChangeNumber("")
                    }
                }
                placeholder("예)930312")
                maxLength(6)
                style {
                    padding(12.px)
                    border {
                        style(LineStyle.Solid)
                        color(when (state) {
                            is InputState.Error -> Colors.errorColor.toCssColor()
                            is InputState.Confirm -> Colors.primaryColor.toCssColor()
                            is InputState.Empty -> Colors.secondaryGreyColor.toCssColor()
                        })
                        width(1.25.px)
                    }
                    height(56.px)
                    borderRadius(6.px)
                    boxSizing("border-box")
                    fontSize(14.px.dynamicSize())
                    width(100.percent)
                }
            })
            if (state is InputState.Error) {
                Span(attrs = {
                    style {
                        position(Position.Absolute)
                        right(12.px)
                        top(16.px)
                        color(Colors.errorColor.toCssColor())
                        display(DisplayStyle.Flex)
                        alignItems(AlignItems.Center)
                        justifyContent(JustifyContent.Center)
                    }
                }) {
                    Img("error_mark.png", attrs = {
                        style {
                            width(24.px)
                            height(24.px)
                        }
                    })
                }
            }
            if (state is InputState.Error) {
                P(attrs = {
                    style {
                        marginTop(4.px)
                        color(Colors.errorColor.toCssColor())
                        fontSize(12.px.dynamicSize())
                    }
                }) {
                    Text(errorMessage)
                }
            }
        }
    }
}

@Composable
fun PhoneNumberInput(
    onChangeNumber: (String) -> Unit
) {
    var registrationNumber by remember { mutableStateOf("") }
    var state by remember { mutableStateOf<InputState>(InputState.Empty) }
    var errorMessage by remember { mutableStateOf("") }

    fun validatePhoneNumber(input: String): Boolean {

        return true
    }

    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            gap(12.px)
            position(Position.Relative)
            width(100.percent)
        }
    }) {
        Div(attrs = {
            style {
                width(100.percent)
            }
        }) {
            Input(type = InputType.Text, attrs = {
                value(registrationNumber)
                onInput {
                    val input = it.value.filter { char -> char.isDigit() }.take(6)
                    registrationNumber = input
                    state = when {
                        input.isEmpty() -> InputState.Empty
                        input.length != 6 || !validatePhoneNumber(input) -> InputState.Error
                        else -> InputState.Confirm
                    }
                    errorMessage = when (state) {
                        is InputState.Empty -> ""
                        is InputState.Error -> "정확한 핸드폰 번호를 입력부탁드립니다."
                        else -> ""
                    }
                    if (state == InputState.Confirm) {
                        onChangeNumber(input)
                    } else {
                        onChangeNumber("")
                    }
                }
                placeholder("예)01011112222")
                maxLength(11)
                style {
                    padding(12.px)
                    border {
                        style(LineStyle.Solid)
                        color(when (state) {
                            is InputState.Error -> Colors.errorColor.toCssColor()
                            is InputState.Confirm -> Colors.primaryColor.toCssColor()
                            is InputState.Empty -> Colors.secondaryGreyColor.toCssColor()
                        })
                        width(1.25.px)
                    }
                    height(56.px)
                    borderRadius(6.px)
                    boxSizing("border-box")
                    fontSize(14.px.dynamicSize())
                    width(100.percent)
                }
            })
            if (state is InputState.Error) {
                Span(attrs = {
                    style {
                        position(Position.Absolute)
                        right(12.px)
                        top(16.px)
                        color(Colors.errorColor.toCssColor())
                        display(DisplayStyle.Flex)
                        alignItems(AlignItems.Center)
                        justifyContent(JustifyContent.Center)
                    }
                }) {
                    Img("error_mark.png", attrs = {
                        style {
                            width(24.px)
                            height(24.px)
                        }
                    })
                }
            }
            if (state is InputState.Error) {
                P(attrs = {
                    style {
                        marginTop(4.px)
                        color(Colors.errorColor.toCssColor())
                        fontSize(12.px.dynamicSize())
                    }
                }) {
                    Text(errorMessage)
                }
            }
        }
    }
}

@Composable
fun<T> SimpleInput(
    hint: String,
    maxLength: Int,
    inputType: InputType<T>,
    onChangeName: (String) -> Unit
) {
    var name by remember { mutableStateOf("") }
    var state by remember { mutableStateOf<InputState>(InputState.Empty) }

    Div(attrs = {
        style {
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            gap(12.px)
            position(Position.Relative)
            width(100.percent)
        }
    }) {
        Div(attrs = {
            style {
                width(100.percent)
            }
        }) {
            Input(type = inputType, attrs = {
                value(name)
                onInput {
                    val inputElement = it.target as? org.w3c.dom.HTMLInputElement
                    val inputText = inputElement?.value ?: ""
                    val input = inputText.take(maxLength)
                    name = input
                    state = when {
                        input.isEmpty() -> InputState.Empty
                        else -> InputState.Confirm
                    }
                    if (state == InputState.Confirm) {
                        onChangeName(input)
                    } else {
                        onChangeName("")
                    }
                }
                placeholder(hint)
                maxLength(maxLength)
                style {
                    padding(12.px)
                    border {
                        style(LineStyle.Solid)
                        color(when (state) {
                            is InputState.Error -> Colors.errorColor.toCssColor()
                            is InputState.Confirm -> Colors.primaryColor.toCssColor()
                            is InputState.Empty -> Colors.secondaryGreyColor.toCssColor()
                        })
                        width(1.25.px)
                    }
                    height(56.px)
                    borderRadius(6.px)
                    boxSizing("border-box")
                    fontSize(14.px.dynamicSize())
                    width(100.percent)
                }
            })
        }
    }
}