package com.damda.webapp.views.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.damda.insurance.themes.Colors
import com.damda.webapp.views.core.JSViewPadding
import com.damda.webapp.views.core.dynamicSize
import com.damda.webapp.views.datas.BannerRadioButtonPresentation
import com.damda.webapp.views.images.JSImage
import com.damda.webapp.views.texts.StyledText
import com.damda.webapp.views.themes.toCssColor
import kotlinx.browser.window
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.border
import org.jetbrains.compose.web.css.borderRadius
import org.jetbrains.compose.web.css.boxSizing
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.columnGap
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.flexDirection
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.fontWeight
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.minus
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.plus
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.style
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

@Composable
fun RadioButtonSection(
    firstItemTitle: String,
    secondItemTitle: String,
    clickedItem: String,
    onClick: (String) -> Unit
) {
    Div(attrs = {
        style {
            width(100.percent)
            display(DisplayStyle.Flex)
            gap(12.px)
            boxSizing("border-box")
        }
    }) {
        RadioButton(
            text = firstItemTitle,
            isSelected = clickedItem == firstItemTitle,
            onClick = { onClick(firstItemTitle) }
        )
        RadioButton(
            text = secondItemTitle,
            isSelected = clickedItem == secondItemTitle,
            onClick = { onClick(secondItemTitle) }
        )
    }
}

@Composable
fun RadioButton(text: String, isSelected: Boolean, onClick: () -> Unit) {
    val dynamicSelectionColor =
        if (isSelected) Colors.primaryColor.toCssColor() else Colors.secondaryTextColor.toCssColor()
    Button(attrs = {
        onClick { onClick() }
        style {
            display(DisplayStyle.Flex)
            alignItems(AlignItems.Center)
            justifyContent(JustifyContent.Center)
            backgroundColor(Colors.backgroundColor.toCssColor())
            border {
                style(LineStyle.Solid)
                color(dynamicSelectionColor)
                width(1.25.px)
            }
            borderRadius(6.px)
            boxSizing("border-box")
            color(dynamicSelectionColor)
            columnGap(4.px)
            cursor("pointer")
            fontSize(14.px.dynamicSize())
            height(56.px)
            padding(0.px, 8.px)
            position(Position.Relative)
            width(100.percent)
        }
    }) {
        if (isSelected) {
            Span(attrs = {
                style {
                    display(DisplayStyle.Flex)
                    alignItems(AlignItems.Center)
                    marginRight(4.px)
                }
            }) {
                Img("check_mark.svg", attrs = {
                    style {
                        width(20.px)
                        height(20.px)
                    }
                })
            }
        }
        Text(text)
    }
}

@Composable
fun BannerRadioButtonSections(
    presentations: List<BannerRadioButtonPresentation>,
    selectionNumber: Int,
    onChangeSelectionNumber: (Int) -> Unit
) {
    presentations.forEach {
        BannerRadioButton(
            data = it,
            isSelected = selectionNumber == it.number,
            isRecommend = it.isRecommend,
            onButtonClick = {
                if (it.number ==0) return@BannerRadioButton
                onChangeSelectionNumber(it.number)
            }
        )
    }
}

@Composable
fun BannerRadioButton(
    data: BannerRadioButtonPresentation,
    isSelected: Boolean,
    isRecommend: Boolean,
    height: CSSSizeValue<CSSUnit.px> = 72.px,
    onButtonClick: () -> Unit
) {
    val selectedDynamicColor = if (isSelected) Colors.primaryColor.toCssColor() else Color.gray
    Div({
        style {
            padding(16.px)
            border(1.px, LineStyle.Solid, if (isSelected) Colors.primaryColor.toCssColor() else Color.lightgray)
            height(height)
            borderRadius(8.px)
            marginBottom(16.px)
        }
        onClick { onButtonClick() }
    }) {
        // First Row: Plan Name and Badge
        Div({
            style {
                display(DisplayStyle.Flex)
                justifyContent(JustifyContent.SpaceBetween)
                alignItems(AlignItems.Center)
                width(100.percent)
            }
        }) {
            Span({
                style {
                    color(selectedDynamicColor)
                    fontSize(14.px.dynamicSize())
                    fontWeight("bold")
                }
            }) {
                Text(data.leftTopTitle)
            }
            Div({
                style {
                    fontSize(12.px.dynamicSize())
                    display(DisplayStyle.Flex)
                    alignItems(AlignItems.Center)
                }
            }) {
                if (isRecommend) {
                    FilledChip(
                        text = "추천",
                        backgroundColor = Colors.primaryColor.toCssColor(),
                        contentColor = Color.white
                    )
                }
                Span({
                    style {
                        fontSize(14.px.dynamicSize())
                        marginLeft(8.px)
                        color(selectedDynamicColor)
                    }
                }) {
                    Text(data.rightTopTitle)
                }
            }
        }

        Div({
            style {
                marginTop(8.px)
                fontSize(14.px.dynamicSize())
            }
        }) {
            Text(data.leftBottomTitle)
        }

        Div({
            style {
                marginTop(8.px)
                fontWeight(700)
                fontSize(16.px.dynamicSize())
            }
        }) {
            Text(data.rightBottomTitle)
        }
    }
}