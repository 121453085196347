package com.damda.webapp.views.screens

import androidx.compose.runtime.MutableState

sealed class Screen {
    data object StepOne : Screen() {
        override fun toString(): String {
            return "StepOne"
        }
    }
    data object StepSecond : Screen() {
        override fun toString(): String {
            return "StepSecond"
        }
    }
    data object StepThird : Screen() {
        override fun toString(): String {
            return "StepThird"
        }
    }
    data object Complete : Screen() {
        override fun toString(): String {
            return "Complete"
        }
    }
}